  <template>
  <div class="row">
    <div class="col-xxl-12 order-1 order-xxl-1">
      <div class="card-body pt-3 pb-3">
        <b-collapse visible id="collapse-filter">
          <div class="row mb-3">
            <div class="col-lg-2">
              <b-form-group label="PM" label-for="name">
                <b-form-input
                  id="name"
                  placeholder="Podaj PM"
                  v-model="filters.pm"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-2"> 
              <b-form-group label="Nazwa" label-for="contactMetadata">
                <b-form-input
                  id="contactMetadata"
                  placeholder="Podaj nazwę"
                  v-model="filters.name"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-3">
              <b-form-group label="Skrót" label-for="shortcode">
                <b-form-input
                  id="shortcode"
                  placeholder="Podaj skrót"
                  v-model="filters.shortcode"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-2">
              <b-form-group label="Rodzaj" label-for="typeFilter">
                <b-form-select
                  id="typeFilter"
                  :options="filters.options.type"
                  v-model="filters.type"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="col-lg-2">
              <b-form-group
                label="Data przygotowania serii"
                label-for="updatedAtFilterTo"
              >
                <b-form-datepicker
                  reset-button
                  type="date"
                  placeholder="dd.mm.rrrr"
                  size="md"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  id="updatedAtFilterTo"
                  v-model="filters.datePrepared"
                >
                </b-form-datepicker>
              </b-form-group>
            </div>
            <div class="col-lg-2">
              <b-form-group label="Status" label-for="statusFilter">
                <b-form-select
                  id="typeFilter"
                  :options="filters.options.status"
                  v-model="filters.status"
                />
              </b-form-group>
            </div>
          </div>
        </b-collapse>
      </div>

      <!-- <router-link
        to="/soilCards/add"
        v-slot="{ href, navigate, isActive, isExactActive }"
        custom
      > -->
        <b-button @click="addCard" variant="primary" class="mt-3 mb-4"
          >Dodaj nową</b-button
        >
      <!-- </router-link> -->

      <div class="table-responsive">
        <v-data-table
          dense
          :headers="this.headers1"
          :items="this.soilCardsListingData"
          class="table custom-table table-head-custom table-vertical-center table-head-bg table-borderless"
          item-key="id"
          mobile-breakpoint="0"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageText: 'Wyników na stronę',
            pageText: '{0}-{1} na {2}',
            itemsPerPageOptions: [10, 30, 50, 100],
          }"
          :items-per-page="50"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <template v-for="item in items">
                <tr class="random" :key="item.id">
                  <td>
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      {{ getVal(item, "soilMark") }}
                    </a>
                  </td>
                  <td>
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      {{ getVal(item, "soilName") }}
                    </a>
                  </td>
                  <td>
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      {{ getVal(item, "soilShortcode") || getVal(item, "soilShortcut")}}
                    </a>
                  </td>
                  <td>
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      {{ translateType(item.type) }}
                    </a>
                  </td>
                  <td>
                    <a
                      class="btn btn-light btn-xs mr-2"
                      title="Edytuj pożywkę"
                      @click="editItem(item.id)"
                    >
                      <i class="fa fa-edit p-0 text-primary"></i>
                    </a>

                    <a
                      class="btn btn-light btn-xs mr-2"
                      title="Utwórz nową na podstawie tej pożywki"
                      @click="cloneItem(item)"
                    >
                      <i class="fa fa-plus-circle p-0 text-primary"></i>
                    </a>

                    <a
                        class="btn btn-light btn-xs mr-2"
                        title="Drukuj pożywkę"
                        @click="printCard(item.id, getVal(item, 'soilMark'))"
                    >
                      <i
                        class="fas fa-print p-0 text-primary"
                      ></i>
                    </a>

                    <a
                      class="btn btn-icon btn-light btn-sm"
                      title="Usuń pożywkę"
                      @click="remove(item.id)"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          src="media/svg/icons/General/Trash.svg"
                        ></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </a>

                    <b-button
                      type="button"
                      @click="handleCollapse(item.id)"
                      class="btn btn-light btn-xs"
                    >
                      <template v-if="isCollapsed(item.id)">
                        <i class="fa fa-angle-up p-0 text-primary"></i>
                      </template>
                      <template v-else>
                        <i class="fa fa-angle-down p-0 text-primary"></i>
                      </template>
                    </b-button>
                  </td>
                </tr>
                <tr :key="item.id + 1000000">
                  <td class="bg-white" colspan="7">
                    <b-collapse :visible="isCollapsed(item.id)" class="mt-2">
                      <table
                        class="table table-vertical-center table-noradius table-sm"
                      >
                        <thead>
                          <tr style="border-radius: 0 !important">
                            <th
                              class="col-1"
                              v-for="(header, index) in headers2"
                              v-bind:key="'suuub-header' + index"
                            >
                              {{ header.text }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <template
                            v-for="(card2, index2) in item.children"
                          >
                            <tr
                              class="cursor-pointer text-hover-primary bg-secondary"
                              v-bind:key="'second-level-key' + index2"
                            >
                              <td>
                                <a
                                  class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                                >
                                  {{ getVal(card2, "soilMark") }} / {{ getVal(card2, "soilSerialNo") }}
                                </a>
                              </td>
                              <td>
                                <a
                                  class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                                >
                                  {{ getVal(card2, "soilName") }}
                                </a>
                              </td>
                              <td>
                                <a
                                  class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                                >
                                {{ getVal(card2, "soilShortcode") || getVal(card2, "soilShortcut")}}
                                </a>
                              </td>
                              <td>
                                <a
                                  class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                                >
                                  {{ translateType(card2.type) }}
                                </a>
                              </td>
                              <td>
                                <a
                                  class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                                  style="dispaly: flex"
                                >
                                  <b-form-select
                                    @input="handleStatusChange($event, card2)"
                                    size="sm"
                                    id="statusSelector"
                                    :value="card2.status"
                                    :options="statusesAvailable"
                                  ></b-form-select>

                                  <svg v-show="statusChangeArray.includes(card2.id)" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35" height="35"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                      <path fill="#3699FF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                        <animateTransform 
                                           attributeName="transform" 
                                           attributeType="XML" 
                                           type="rotate"
                                           dur="1s" 
                                           from="0 50 50"
                                           to="360 50 50" 
                                           repeatCount="indefinite" />
                                    </path>
                                  </svg>
                                </a>
                              </td>
                              <td>
                                <a
                                  class="btn btn-light btn-xs mr-2"
                                  title="Edytuj pożywkę"
                                  @click="editItem(card2.id)"
                                >
                                  <i class="fa fa-edit p-0 text-primary"></i>
                                </a>

                                <a
                                  class="btn btn-light btn-xs mr-2"
                                  title="Utwórz nową na podstawie tej pożywki"
                                  @click="cloneItem(card2)"
                                >
                                  <i
                                    class="fa fa-plus-circle p-0 text-primary"
                                  ></i>
                                </a>

                                <a
                                    class="btn btn-light btn-xs mr-2"
                                    title="Drukuj pożywkę"
                                    @click="printCard(card2.id, ( getVal(card2, 'soilMark') + ' / LOT' + getVal(card2, 'soilSerialNo') ))"
                                >
                                  <i
                                    class="fas fa-print p-0 text-primary"
                                  ></i>
                                </a>

                                <a
                                  class="btn btn-icon btn-light btn-sm"
                                  title="Usuń pożywkę"
                                  @click="remove(card2.id)"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <!--begin::Svg Icon-->
                                    <inline-svg
                                      src="media/svg/icons/General/Trash.svg"
                                    ></inline-svg>
                                    <!--end::Svg Icon-->
                                  </span>
                                </a>

                                <b-button
                                  type="button"
                                  @click="handleCollapse('l2-'+card2.id)"
                                  class="btn btn-light btn-xs"
                                >
                                  <template v-if="isCollapsed('l2-'+card2.id)">
                                    <i
                                      class="fa fa-angle-up p-0 text-primary"
                                    ></i>
                                  </template>
                                  <template v-else>
                                    <i
                                      class="fa fa-angle-down p-0 text-primary"
                                    ></i>
                                  </template>
                                </b-button>
                              </td>
                            </tr>
                            <tr :key="card2.id + 111000000">
                              <td class="bg-white" colspan="7">
                                <b-collapse
                                  :visible="isCollapsed('l2-'+card2.id)"
                                  class="mt-2"
                                >
                                  <table
                                    class="table table-vertical-center table-noradius table-sm"
                                  >
                                    <thead>
                                      <tr style="border-radius: 0 !important">
                                        <th
                                          class="col-1"
                                          v-for="(header, index) in headers"
                                          v-bind:key="'suuub-header' + index"
                                        >
                                          {{ header.text }}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <template
                                        v-for="(
                                          card3, index3
                                        ) in card2.children"
                                      >
                                        <tr
                                          class="cursor-pointer text-hover-primary bg-secondary"
                                          v-bind:key="
                                            'third-level-key' + index3
                                          "
                                        >
                                          <td>
                                            <a
                                              class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                                            >
                                              {{ getVal(card3, "soilMark") }} / {{ getVal(card3, "soilSerialNo") }} / {{ (new Date( Date.parse(getVal(card3, "soilDryExpiry")) )).toLocaleDateString('pl-PL') }}
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                                            >
                                              {{ getVal(card3, "soilName") }}
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                                            >
                                              {{ getVal(card3, "soilShortcode") || getVal(card3, "soilShortcut")}}
                                            </a>
                                          </td>
                                          <td> 
                                            <a
                                              class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                                            >
                                              {{ translateType(card3.type) }}
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                                            >
                                              {{
                                                getVal(card3, "preparationDate").split('T')[0]
                                              }}
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                                              style="display: flex"
                                            >
                                              <b-form-select
                                                @input="handleStatusChange($event, card3)"
                                                size="sm"
                                                id="statusSelector"
                                                :value="card3.status"
                                                :options="statusesAvailable"
                                              ></b-form-select>

                                              <svg v-show="statusChangeArray.includes(card3.id)" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35" height="35"
                                                viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                                  <path fill="#3699FF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                    <animateTransform 
                                                       attributeName="transform" 
                                                       attributeType="XML" 
                                                       type="rotate"
                                                       dur="1s" 
                                                       from="0 50 50"
                                                       to="360 50 50" 
                                                       repeatCount="indefinite" />
                                                </path>
                                              </svg>
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              class="btn btn-light btn-xs mr-2"
                                              title="Edytuj pożywkę"
                                              @click="editItem(card3.id)"
                                            >
                                              <i
                                                class="fa fa-edit p-0 text-primary"
                                              ></i>
                                            </a>

                                            <a
                                                class="btn btn-light btn-xs mr-2"
                                                title="Drukuj pożywkę"
                                                @click="printCard(card3.id, getVal(card3, 'soilMark') + ' / LOT' + getVal(card3, 'soilSerialNo') + ' / DATA' + (new Date( Date.parse(getVal(card3, 'soilDryExpiry')) )).toLocaleDateString('pl-PL'))"
                                            >
                                              <i
                                                class="fas fa-print p-0 text-primary"
                                              ></i>
                                            </a>

                                            <a
                                              class="btn btn-icon btn-light btn-sm"
                                              title="Usuń pożywkę"
                                              @click="remove(card3.id)"
                                            >
                                              <span
                                                class="svg-icon svg-icon-md svg-icon-primary"
                                              >
                                                <!--begin::Svg Icon-->
                                                <inline-svg
                                                  src="media/svg/icons/General/Trash.svg"
                                                ></inline-svg>
                                                <!--end::Svg Icon-->
                                              </span>
                                            </a>
                                          </td>
                                        </tr>
                                      </template>
                                    </tbody>
                                  </table>
                                </b-collapse>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </b-collapse>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import JwtService from "../../../../core/services/jwt.service";
import ApiService from "../../../../core/services/api.service";
import { SOILCARD_RESOURCE } from "../../../../core/services/store/soil.module";
import {
  getThirdLevelCards,
  getSecondLevelCards,
  getCardParamValue,
} from "../../../../core/services/store/soil.module";

export default {
  name: "SoilCardsIndex",
  methods: {
    addCard() {
      this.$router.push('/soilCards/add')
    },
    printCard(id, file_name) {
      this.$store.dispatch("soilCardPrint", {
        id,
        file_name: file_name.replace(' / ', '_')
      });
    },
    async handleStatusChange($event, soilCard) {
      let newValue = $event;

      this.statusChangeArray.push( soilCard.id );

      await this.$store.dispatch('soilCardUpdateStatus', {
        id: soilCard.id,
        status: newValue
      })

      setTimeout(() => {
        const index = this.statusChangeArray.indexOf(soilCard.id);
        if (index > -1) {
          // Use Vue's splice method to maintain reactivity
          this.statusChangeArray.splice(index, 1);
        }
      }, 1000);
    },
    getVal(item, param) {
      if (!item) return "";

      return getCardParamValue(item, param);
    },
    async cloneItem(item) {
      if (JwtService.getToken()) {
        ApiService.setHeader();

        let newItem = {
          ...item,
          date: new Date(item.date.date),
          expiry_date: new Date(item.expiry_date.date),
          open_date: new Date(item.open_date.date),
          preparation_date: new Date(item.preparation_date.date),
          test_date: new Date(item.test_date.date),
          level: parseInt(item.level) + 1,
          parent_id: item.id,
          clone: true
        };
        delete newItem.id;
        delete newItem.test_user;
        // 1. Set store for clone
        // 2. Redirect to clone edit page
        // 3. Get data from store to populate form
        // 4. On save create new item and clear store clone data
        try {
          await this.$store.commit('setCloneCard', true);
          let { data } = await ApiService.post(SOILCARD_RESOURCE, newItem);
          await this.$store.dispatch("soilCardsList");
          await this.$router.push("/soilCards/" + data.data.id + "/edit");
        } catch (e) {
          this.$store.commit("setError", e);
        }
      }
    },
    remove(id) {
      Swal.fire({
        title: "Na pewno usunąć?",
        text: "Na pewno chcesz to zrobić?",
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-primary",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("soilCardDelete", { id });
          Swal.fire("Gotowe!", "Usunięto wpis.", "success");
        }
      });
    },
    editItem(id) {
      this.$router.push("/soilCards/" + id + "/edit");
    },
    translateStage(stage) {
      if (stage === "prepared") return "Przygotowana";

      if (stage === "bought") return "Zakupiona";

      return "Brak danych";
    },
    translateStatus(status) {
      if (status === "in-use") return "W użyciu";

      if (status === "finished") return "Zakończona";

      if (status === "new") return "Nowa"

      return "Brak danych";
    },
    translateType(type) {
      if (type === "ready") return "Pożywka gotowa";

      if (type === "test") return "Testy i suplementy";

      if (type === "dry") return "Pożywka sucha";

      return "brak danych";
    },
    isCollapsed(id) {
      return this.collapsed.includes(id);
    },
    handleCollapse(id) {
      if (this.collapsed.includes(id))
        this.collapsed = [...this.collapsed.filter((el) => el != id)];
      else this.collapsed = [...this.collapsed, id];
    },
    getSecondLevelList(mark) {
      return getSecondLevelCards(this.soilCardsAll, mark);
    },
    getThirdLevelList(mark, serial) {
      return getThirdLevelCards(this.soilCardsAll, mark, serial);
    },
    filterCards(el) {
      let filterKeys = Object
          .keys(this.filters)
          .filter(key => key != 'options')
          .filter(key => this.filters[key].length > 0 && this.filters[key] != 'all');

        if ( filterKeys.length == 0 )
          return true;

        let keyMap = {
          pm: "soilMark",
          name: "soilName",
          shortcode: "soilShortcode", // also soilShortcut
          type: "type", 
          // datePrepared: "soilDryExpiry",
          datePrepared: "preparationDate",
          status: "status"
        };

        if ( this.getVal(el, 'soilShortcut') )
          keyMap.shortcode = "soilShortcut";

        filterKeys = filterKeys.map(key => {
          return el.filterIndexes[keyMap[key]].some( el => {
            return String(el).toLowerCase().trim().includes( 
              String(this.filters[key]).toLowerCase().trim() 
            )
          } );
        })

        return !filterKeys.some(key => !key);
    },
  },
  data() {
    return {
      statusChangeArray: [],
      statusesAvailable: [
        { value: "in-use", text: "W użyciu" },
        { value: "finished", text: "Zakończona" },
        { value: "new", text: "Nowa" },
      ],
      filters: {
        pm: "",
        name: "",
        shortcode: "",
        type: "all",
        datePrepared: "",
        status: "all",
        options: {
          status: [
            {
              value: "all",
              text: "Wszystkie"
            },
            {
              value: 'new',
              text: 'Nowa'
            },
            {
              value: 'in-use',
              text: 'W użyciu',
            },
            {
              value: 'finished',
              text: 'Zakończone',
            },
          ],
          type: [
            {
              value: "all",
              text: "Wszystkie"
            },
            {
              value: 'ready',
              text: 'Pożywka gotowa',
            },
            {
              value: 'test',
              text: 'Testy i suplementy',
            },
            {
              value: 'dry',
              text: 'Pożywka Sucha',
            },
          ]
        }
      },
      headers: [
        {
          text: "PM",
          value: "soilMark",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Nazwa",
          value: "code",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Skrót",
          value: "use",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Rodzaj",
          value: "stage",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Data przygotowania serii",
          value: "status",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Status",
          value: "type",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Akcja",
          value: "action",
          style: "min-width: 80px",
          sortable: true,
        },
      ],
      headers1: [
        {
          text: "PM",
          value: "soilMark",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Nazwa",
          value: "code",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Skrót",
          value: "use",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Rodzaj",
          value: "stage",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Akcja",
          value: "action",
          style: "min-width: 80px",
          sortable: true,
        },
      ],
      headers2: [
        {
          text: "PM",
          value: "soilMark",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Nazwa",
          value: "code",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Skrót",
          value: "use",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Rodzaj",
          value: "stage",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Status",
          value: "type",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Akcja",
          value: "action",
          style: "min-width: 80px",
          sortable: true,
        },
      ],
      collapsed: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Panel laboratorium - pożywki", route: "soilCards" },
    ]);

    this.$store.dispatch("soilCardsList");
  },
  computed: {
    ...mapGetters(["soilCardsAll", "soilCardsListing"]),
    soilCardsListingData() {
      return this 
        .soilCardsListing
        .filter(this.filterCards)
        .map(el => {
          return {
            ...el,
            children: [
              ...el
                .children
                .filter(this.filterCards)
                .map(child => {
                  return {
                    ...child,
                    children: [
                      ...child.children.filter(this.filterCards)
                    ]
                  }
                })
            ]
          }
        });
    }
  }
};
</script>

<style scoped></style>
